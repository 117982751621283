<script setup>
import { VueFinalModal } from "vue-final-modal";

// eslint-disable-next-line no-undef
const emit = defineEmits({
  confirm: null,
});
</script>

<template>
  <vue-final-modal
    class="resolution-alert"
    overlay-class="resolution-alert__overlay"
  >
    <div class="offline">
      <div class="offline__content">
        <div class="offline-icon">
          <v-icon
            name="offline"
            width="436"
            height="218"
            stroke="none"
            action-color="transparent"
          />
        </div>
        <div class="offline__text-block">
          <div class="offline__text-block-title">Не может быть!</div>

          <div class="offline__text-block-content">
            Отсутствует интернет-соединение
          </div>
        </div>
      </div>
    </div>
  </vue-final-modal>
</template>
<style lang="scss">
.resolution-alert {
  display: flex;
  justify-content: center;
  align-items: center;
  &__overlay {
    background: rgba(14, 14, 34, 0.8) !important;
    backdrop-filter: blur(2px) !important;
  }
}
.offline {
  position: relative !important;
  background: #ffffff;
  border-radius: 20px;
  padding: 32px 24px;
  position: relative;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.08);
  &__icons {
    display: flex;
    gap: 12px;
    justify-content: center;
    &--text {
      color: #bcbbbb;
      font: 400 12px/130% "Inter", sans-serif;
      text-align: center;
      line-height: 52px;
    }
  }
  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__text-block {
    display: flex;
    flex-direction: column;
    gap: 8px;
    &-title {
      text-align: center;
      font: 600 32px / 130% "Roboto", sans-serif;
      color: #0e0e22;
    }
    &-content {
      text-align: center;
      font: 400 16px / 125% "Roboto", sans-serif;
      color: #595a5b;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    gap: 32px;
    align-items: center;
    justify-content: center;
    width: 320px;
  }
  &__close {
    position: absolute !important;
    top: 8px !important;
    right: 8px !important;
    cursor: pointer;
  }
}
.offline-icon {
  transform: rotate(180deg);
  transition: transform 1s ease-out;
  transition-delay: 2s;
  &:hover {
    transform: rotate(0deg);
  }
}
</style>
