<template>
  <svg
    :class="svgClass"
    class="x-close"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.9961 6L6.99609 18M6.99609 6L18.9961 18"
      stroke="#0E0E22"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script setup>
defineProps({
  svgClass: {
    type: String,
    default: "",
  },
});
</script>
