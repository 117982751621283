<template>
  <div class="error-wrapper">
    <slot />
    <span
      v-if="isError && !errorObject.$pending"
      class="error-address"
      :class="{ 'error-address__textarea': isTextarea }"
    >
      {{ "&nbsp;" + errorMessage }}
    </span>
  </div>
</template>

<script setup>
// eslint-disable-next-line no-undef
defineProps({
  errorMessage: { type: String, default: "" },
  isError: { type: Boolean, default: false },
  isTextarea: { type: Boolean, default: false },
  errorObject: { type: Object, default: () => ({ $pending: false }) },
});
</script>

<style lang="scss" scoped>
.error-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.error-address {
  color: var(--status-red, #f73a53);
  text-align: left;
  font: var(--dashboard-textsm, 400 12px/130% "Inter", sans-serif);
  position: relative;
  &__textarea {
    position: absolute;
    bottom: 0;
  }
}
</style>
