<template>
  <div v-bind="$attrs" class="cell">
    <div 
      v-if="!noTitle" 
      class="cell--title"
      :class="{ 'flex-column': isColumn }"
        >
      <div class="cell--title__wrapper">
        <div :class="['cell--caption', { 'cell--disabled': disabled }]">
          {{ caption }}
        </div>
        <div v-if="required" class="cell--caption red">*</div>
      </div>
      <div :class="['cell--desc', { 'cell--disabled': disabled }]">
        {{ desc }}
      </div>
    </div>
    <div
      v-bind="$attrs"
      :class="['cell--content', { 'cell--disabled': disabled }]"
    >
      <slot />
    </div>
  </div>
</template>

<script setup>
// eslint-disable-next-line no-undef
defineProps({
  caption: {
    type: String,
    default: "",
  },
  desc: {
    type: String,
    default: "",
  },
  required: {
    type: Boolean,
    default: () => false,
  },
  disabled: {
    type: Boolean,
    default: () => false,
  },
  noTitle: {
    type: Boolean,
    default: () => false,
  },
  isColumn: {
    type: Boolean,
    default: () => false,
  },
});
</script>

<style lang="scss" scoped>
.red {
  color: red;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
</style>
